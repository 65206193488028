import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import GUI from 'lil-gui'
import earthVertexShader from './shaders/earth/vertex.glsl'
import earthFragmentShader from './shaders/earth/fragment.glsl'
import atmosphereFragmentShader from './shaders/atmosphere/fragment.glsl'

/**
 * Base
 */
// Debug
const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Loaders
const textureLoader = new THREE.TextureLoader()
const dayTexture = textureLoader.load('./earth/day.jpg')
const nightTexture = textureLoader.load('./earth/night.jpg')
const cloudsTexture = textureLoader.load('./earth/specularClouds.jpg')
dayTexture.colorSpace = THREE.SRGBColorSpace
nightTexture.colorSpace = THREE.SRGBColorSpace

nightTexture.anisotropy = 8
dayTexture.anisotropy = 8
cloudsTexture.anisotropy = 8




/**
 * Earth
 */
// Mesh

const earthGeometry = new THREE.SphereGeometry(2, 64, 64)
const earthMaterial = new THREE.ShaderMaterial({
    vertexShader: earthVertexShader,
    fragmentShader: earthFragmentShader,
    uniforms:
    {
        day_texture: new THREE.Uniform(dayTexture),
        night_texture: new THREE.Uniform(nightTexture),
        clouds_texture: new THREE.Uniform(cloudsTexture),
        u_sunPosition: new THREE.Uniform(new THREE.Vector3(0, 0, 1)),
        smoothVal: new THREE.Uniform(0.5),
        u_clearAtmosphereColor: new THREE.Uniform( new THREE.Color('#00aaff') ),
        u_darkAtmosphereColor: new THREE.Uniform( new THREE.Color('#ff6600') )
    }
})
const earth = new THREE.Mesh(earthGeometry, earthMaterial)
scene.add(earth)

const atmosphere = new THREE.Mesh(
    earthGeometry,
    new THREE.ShaderMaterial({
        vertexShader: earthVertexShader,
        fragmentShader: atmosphereFragmentShader,
        side: THREE.BackSide,
        transparent: true,
        uniforms: {
            u_sunPosition: new THREE.Uniform(new THREE.Vector3(0, 0, 1)),
            u_clearAtmosphereColor: new THREE.Uniform( new THREE.Color('#00aaff') ),
            u_darkAtmosphereColor: new THREE.Uniform( new THREE.Color('#ff6600') )
        }
    })
)
atmosphere.scale.set(1.04, 1.04, 1.04)

scene.add(atmosphere)
// Sun
const sunSpherical = new THREE.Spherical(1, Math.PI * 0.5, 0.5)
const sunDirection = new THREE.Vector3()

const sunHelper = new THREE.Mesh(
    new THREE.SphereGeometry(0.1, 32, 32),
    new THREE.MeshBasicMaterial({ color: 'white' })
)
scene.add(sunHelper)

const updateSun = () => {
    //sun direction
    sunDirection.setFromSpherical(sunSpherical)
    sunHelper.position.set(sunDirection.x * 5, sunDirection.y * 5, sunDirection.z * 5)

    earthMaterial.uniforms.u_sunPosition.value.copy(sunDirection)
    atmosphere.material.uniforms.u_sunPosition.value.copy(sunDirection)

}
updateSun()

const debugColor = {}
debugColor.clearColor = '#00aaff'
debugColor.darkColor = '#ff6600'

// GUI
gui.add(sunSpherical, 'phi')
    .min(0)
    .max(Math.PI)
    .onChange(updateSun)

gui.add(sunSpherical, 'theta')
    .min(-Math.PI)
    .max(Math.PI)
    .onChange(updateSun)
gui.add(earthMaterial.uniforms.smoothVal, 'value')
    .name('clouds')
    .min(0)
    .max(0.9)
    .step(0.01)

    gui.addColor(debugColor, 'clearColor')
        .onChange(()=>{
            earthMaterial.uniforms.u_clearAtmosphereColor.value.set(debugColor.clearColor)
            atmosphere.material.uniforms.u_clearAtmosphereColor.value.set(debugColor.clearColor)
        })
    gui.addColor(debugColor, 'darkColor')
        .onChange(()=>{
            earthMaterial.uniforms.u_darkAtmosphereColor.value.set(debugColor.darkColor)
            atmosphere.material.uniforms.u_darkAtmosphereColor.value.set(debugColor.darkColor)
        })


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
    pixelRatio: Math.min(window.devicePixelRatio, 2)
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    sizes.pixelRatio = Math.min(window.devicePixelRatio, 2)

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(sizes.pixelRatio)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(25, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 12
camera.position.y = 5
camera.position.z = 4
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(sizes.pixelRatio)
// renderer.setClearColor('#999999')
renderer.setClearColor('#000011')


/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    earth.rotation.y = elapsedTime * 0.1

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()