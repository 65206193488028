varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition;

uniform sampler2D day_texture;
uniform sampler2D night_texture;
uniform sampler2D clouds_texture;
uniform vec3 u_sunPosition;
uniform vec3 u_clearAtmosphereColor;
uniform vec3 u_darkAtmosphereColor;
uniform float smoothVal;
void main()
{

    vec3 day = texture(day_texture, vUv).rgb;
    vec3 night = texture(night_texture, vUv).rgb;
    vec3 clouds = texture(clouds_texture, vUv).rgb;
    
    vec3 viewDirection = normalize(vPosition - cameraPosition);
    vec3 normal = normalize(vNormal);

    // float shading = dot(normal, vec3(0., 0., 1.)) * 0.5 + 0.7;
    // shading = smoothstep(0.3, 0.7, shading);
    // shading *= clouds.y;
    // vec3 earthColor = mix(day, night, shading );
    // 
    // Sun orientation
    float sunOrientation = dot(normal, u_sunPosition);
    
    // day night color
    vec3 color = vec3(0.0);
    float dayMix = smoothstep(-0.25, 0.5, sunOrientation);
    color = mix(night, day, dayMix);
    // color = vec3(dayMix);

    // specular and clouds color

    float cloudsMix = smoothstep(smoothVal, 0.9, clouds.g);
    cloudsMix *= dayMix;
    color = mix(color, vec3(1.0), cloudsMix);


    // fresnel
    // faire un sh"ma pour comprendre ici le but est d'avoir 0 au centre (avant l'ajout de 1 on avait -1 car vecteur oposés) et 1 aux edges. avoir 2 derière importe peux car ca suis la vue de la camera
    float fresnel = dot(viewDirection, normal)+1.0;
    fresnel = pow(fresnel, 2.0);


    // atmosphere

    float atmosphereDayMix = smoothstep(-0.5, 1.0, sunOrientation);
    float atmosphereNightMix = smoothstep(1.0, -1.0, sunOrientation);

    vec3 atmosphereColor = mix(u_darkAtmosphereColor, u_clearAtmosphereColor, atmosphereDayMix);

    color = mix(color, atmosphereColor, fresnel * atmosphereDayMix );

    // specular

    vec3 reflection = reflect(u_sunPosition, normal);

    
    float specular = max(dot(reflection, viewDirection), 0.0);
     specular = pow(specular, 32.0);

      vec3 specColor = mix(vec3(1.), atmosphereColor, fresnel);

    color += specular * specColor * clouds.r;






    

    // Final color
    gl_FragColor = vec4(color, 1.0);
    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}