varying vec2 vUv;
varying vec3 vNormal, vPosition;
uniform vec3 u_sunPosition, u_clearAtmosphereColor, u_darkAtmosphereColor;

void main() {
    vec3 normal = normalize(vNormal);
    float sunOrientation = dot(normal, u_sunPosition);
    vec3 viewDirection = normalize(vPosition - cameraPosition);
  
    float fresnel = pow(dot(viewDirection, normal) + 1.0, 2.0);
  
    float atmosphereDayMix = smoothstep(-0.5, 1.0, sunOrientation);
    vec3 atmosphereColor = mix(u_darkAtmosphereColor, u_clearAtmosphereColor, atmosphereDayMix);
  
    // vec3 color = mix(vec3(0.0), atmosphereColor, fresnel * atmosphereDayMix);

    // float shading = 1.0 - pow(1.0 - dot(viewDirection, normal), 10.0);
    // color *= vec3(shading);

    // sa maniere de faire
  vec3 color = vec3(0.0);
    color += atmosphereColor;

    // alpha 
    float edgeAlpha = dot(viewDirection, normal);
    edgeAlpha = smoothstep(0.0, 0.5, edgeAlpha);
    float dayAlpha = smoothstep(-0.5, 0.0, sunOrientation);


    gl_FragColor = vec4(color, edgeAlpha * dayAlpha);
    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}
